import React from 'react';
import Helmet from 'react-helmet';

import AppContext, { UserContext } from "../../components/AppContext";
import NavBar from "../../components/NavBar/NavBar";
import { Footer } from "../../components/Footer/Footer";
import { VideoSection } from '../../components/AboutSample/React/VideoSection';
import { Hero } from '../../components/AboutSample/React/Hero';
import { Hero3 } from '../../components/AboutSample/React/Hero3';
import { Hero2 } from '../../components/AboutSample/React/Hero2';
import { Hero4 } from '../../components/AboutSample/React/Hero4';
import { Feature } from '../../components/AboutSample/React/Feature';
import { Showcase } from '../../components/AboutSample/React/Showcase';
import { PagesShowcase } from '../../components/AboutSample/React/PagesShowcase';
import { DocsPreview } from '../../components/AboutSample/React/DocsPreview';
import { Comparison } from '../../components/AboutSample/React/Comparison';
import { PricingPlans } from '../../components/AboutSample/React/PricingPlans';
import { FeaturedIn } from '../../components/FeaturedIn/FeaturedIn';
import '../../styles/scss/style.scss'

const AdminContrastPro = () => {

  return (
    <AppContext>
			<Helmet>
				<title>Devwares - Tools and resources for developers and designers </title>
				<meta name="title" content="Devwares - Tools and resources for developers and designers" />
				<meta
					name="description"
					content="We are creating high quality tools and resources to aid developers and designers during the development of their projects"
				/>
				<meta
					property="og:title"
					content="Devwares - Tools and resources for developers and designers "
				/>
				<meta
					property="og:description"
					content="We are creating high quality tools and resources to aid developers and designers during the development of their projects"
				/>
				<meta
					property="twitter:title"
					content="Devwares - Tools and resources for developers and designers"
				/>
				<meta
					property="twitter:description"
					content="We are creating high quality tools and resources to aid developers and designers during the development of their projects"
				/>
				<link rel="canonical" href="https://www.devwares.com/" />
				<meta property="og:url" content="https://www.devwares.com/" />
				<meta property="og:site_name" content="Devwares" />
				<meta name="twitter:card" content="summary_large_image" />
			</Helmet>
      <UserContext.Consumer>
      {({ user }) => (
      	<div>
    			<div className="container">
						<NavBar user={user}/>
					</div>
					<Hero />
					<VideoSection />
					<div className="mt-5">
      			<FeaturedIn/>
					</div>
					<Feature />
					<Hero3 />
					<Hero2 />
					<Hero4 />
					<PagesShowcase />
					<DocsPreview />
					<Showcase />
					<Comparison />
					<PricingPlans />
    			<div className="container">
						<Footer />
					</div>
				</div>
			)}
      </UserContext.Consumer>
    </AppContext>
  );
};

export default AdminContrastPro;
