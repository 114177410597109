import React, { Fragment } from 'react';


export const PricingPlans = () => {
  return (
    <div
      className="py-5"
      style={{ minHeight: '100vh', background: 'rgba(26,32,44,1)', marginTop: '5rem' }}
    >
      <div className="comparison container pt-5">
        <div className="comparison__title">
          <h2 className="text-light comparison__lead mb-5 pb-4">Pricing Plans</h2>
          {/* 
          <p className="comparison__sub-title">
            We understand that development should not be expensive and as such our prices are
            utterly affordable and pocket friendly.
            <br />
            Happy Building
          </p> */}
        </div>
        <div className="comparison__wrap">
          <div className="comparison__box">
            <div className="comparison__price">$49</div>
            <h2 className="comparison__package-name">Individual</h2>
            <p>Great for personal use and for your side projects.</p>

            <ul className="comparison__list">
              <li>Team size: 1</li>
              <li>Full Documentation</li>
              <li>10000+ components</li>
              <li>15+ example pages</li>
              <li>Lifetime Premium Support</li>
              <li>Lifetime Unlimited Updates</li>
              <li>VIP Early access</li>
            </ul>
            <a
              href="https://gum.co/OdHKj"
              target="_blank"
              rel="noreferrer"
              className="btn1 btn__inverse btn__full"
            >
              Buy Now
            </a>
          </div>
          <div className="comparison__box">
            <div className="comparison__price">$109</div>
            <h2 className="comparison__package-name">Startup</h2>
            <p>Great for Startup Company/Projects </p>
            <ul className="comparison__list">
              <li>Team size: 1 - 5</li>
              <li>Full Documentation</li>
              <li>10000+ components</li>
              <li>15+ example pages</li>
              <li>Lifetime Premium Support</li>
              <li>Lifetime Unlimited Updates</li>
              <li>VIP Early access</li>
            </ul>
            <a
              href="https://gum.co/OdHKj"
              target="_blank"
              rel="noreferrer"
              className="btn1 btn__inverse btn__full"
            >
              Buy Now
            </a>
          </div>
          <div className="comparison__box">
            <div className="comparison__price">$219</div>
            <h2 className="comparison__package-name">Small Business</h2>
            <p>Awesome for small business/small SaaS company.</p>
            <ul className="comparison__list">
              <li>Team size: 5 - 10</li>
              <li>Full Documentation</li>
              <li>10000+ components</li>
              <li>15+ example pages</li>
              <li>Lifetime Premium Support</li>
              <li>Lifetime Unlimited Updates</li>
              <li>VIP Early access</li>
            </ul>
            <a
              href="https://gum.co/OdHKj"
              target="_blank"
              rel="noreferrer"
              className="btn1 btn__inverse btn__full"
            >
              Buy Now
            </a>
          </div>
          <div className="comparison__box">
            <div className="comparison__price">Contact Us</div>
            <h2 className="comparison__package-name">Enterprise</h2>
            <p>Best for large scale uses and extended redistribution rights.</p>
            <ul className="comparison__list">
              <li>Team size: 10+ </li>
              <li>Full Documentation</li>
              <li>10000+ components</li>
              <li>15+ example pages</li>
              <li>Lifetime Premium Support</li>
              <li>Lifetime Unlimited Updates</li>
              <li>VIP Early access</li>
            </ul>
            <a href="/contact" className="btn1 btn__inverse btn__full">
              Buy Now
            </a>
          </div>
        </div>
      </div>
      <p className="comparison__footer-info">Secured Payment by Gumroad</p>
    </div>
  );
};
